<template>
  <div class="st-radio-group" data-t="st-radio-group">
    <slot />
  </div>
</template>

<script setup lang="ts">
interface Props {
  modelValue?: string | number
  disabled?: boolean
  size?: 's' | 'm'
  name: string
}

interface Emit {
  (e: 'update:modelValue', value: string | number): void
}

interface Provide {
  props: Props
  emit: Emit
}

const props = defineProps<Props>()
const emit = defineEmits<Emit>()

const injectedOptions = {
  props,
  emit,
}

provide<Provide>('buttonGroup', injectedOptions)
</script>

<style scoped>
.st-radio-group {
  display: flex;
  flex-direction: column;
}
</style>
