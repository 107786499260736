<template>
  <label class="st-radio" data-t="st-radio">
    <input
      class="input"
      type="radio"
      :checked="isChecked"
      :disabled="isDisabled"
      :name="buttonGroup.props.name"
      :value="modelData"
      data-t="radio-input"
      @change="inputHandler"
    />
    <span class="checkmark" />
    <span class="st-label"><slot /></span>
  </label>
</template>

<script setup lang="ts">
interface Props {
  disabled?: boolean
  size?: 's' | 'm'
  modelValue?: string | number
  modelData?: number | string
}

interface Emit {
  (e: 'update:modelValue', value?: string | number): void
}

interface Inject {
  props: {
    modelValue?: string | number
    disabled?: boolean
    name: string
  }
  emit: Emit
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  disabled: false,
  size: 'm',
})
const emit = defineEmits<Emit>()
const buttonGroup = inject<Inject>('buttonGroup', {
  props: {
    name: 'default',
  },
  emit,
})

function inputHandler() {
  if (buttonGroup.props.modelValue) {
    buttonGroup.emit('update:modelValue', props.modelData)
    return
  }
  emit('update:modelValue', props.modelData)
}

const sizeValue = computed(() =>
  props.size === 's'
    ? {
        size: '16px',
        dot: '9px',
        top: '2px',
        left: '2px',
      }
    : {
        size: '20px',
        dot: '11px',
        top: '3px',
        left: '3px',
      },
)
const preparedValue = computed(
  () => buttonGroup.props.modelValue || props.modelValue,
)
const isChecked = computed(() => preparedValue.value === props.modelData)
const isDisabled = computed(() => props.disabled || buttonGroup?.props.disabled)
</script>

<style lang="css" scoped>
.st-radio {
  cursor: pointer;
  position: relative;
  display: block;
}

.input {
  cursor: pointer;

  position: absolute;

  box-sizing: border-box;
  width: 0;
  height: 0;

  opacity: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;

  width: v-bind('sizeValue.size');
  height: v-bind('sizeValue.size');

  background-color: transparent;
  border: 1px solid var(--border-primary);
  border-radius: var(--border-radius-full);
}

.checkmark::after {
  content: '';
  position: absolute;
  display: none;
}

.st-radio .checkmark::after {
  top: v-bind('sizeValue.top');
  left: v-bind('sizeValue.left');

  width: v-bind('sizeValue.dot');
  height: v-bind('sizeValue.dot');

  background: var(--button-primary-default);
  border-radius: var(--border-radius-full);
}

.input:focus ~ .checkmark {
  box-shadow: var(--focus-rings-button-primary);
}

.st-radio .input:checked ~ .checkmark {
  background-color: transparent;
  border: 1.5px solid var(--button-primary-default);
}

.input:checked:focus ~ .checkmark {
  border: 1.5px solid var(--button-primary-active);
}

.input:checked ~ .checkmark:hover {
  border: 1.5px solid var(--button-primary-active);
}

.st-radio .input:disabled + .checkmark {
  cursor: not-allowed;
  background-color: var(--background-primary);
  border: 1.5px solid var(--background-secondary);
}

.st-radio:hover > .input:not(:checked) ~ .checkmark {
  border-color: var(--button-primary-default);
}

.st-radio .input:disabled:checked + .checkmark {
  cursor: not-allowed;

  opacity: 0.32;
  background-color: var(--button-primary-default);
  border: 1.5px solid var(--button-primary-default);
  box-shadow: none;
}

.st-radio:hover > .input:disabled:not(:checked) ~ .checkmark {
  border-color: var(--border-primary);
}

.st-radio .input:checked ~ .checkmark::after {
  display: block;
}

.st-label {
  padding-left: calc(v-bind('sizeValue.size') + 6px);
}
</style>
